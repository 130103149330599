<template>
  <div class="modal fade " id="basicInfoEditModal" tabindex="-1" role="dialog" aria-labelledby="productDetailsModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Update Basic Information</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <h5 class="my-1 text-uppercase">Basic Info</h5>

            <div class="row">
              <div class="col-12 col-sm-6 col-md-9">
                <div class="form-group">
                  <div class="">
                    <label>Name</label>
                    <input v-model="putProductData.name" type="text" class="form-control"
                           placeholder="Enter Product Name">
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Source *</label>
                    <VueMultiselect :allowEmpty="false" v-model="selectedSource" class="multiselect-blue"
                                    :options="sourceOptions"
                                    :close-on-select="true" placeholder="Select source" label="name"
                                    track-by="name"
                                    :show-labels="false"/>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Categories</label>
                    <VueMultiselect :allowEmpty="false" v-model="selectedCategory" class="multiselect-blue"
                                    :options="categoryOptions"
                                    :close-on-select="true" placeholder="Select Category" label="name" track-by="name"
                                    :show-labels="false"/>
                  </div>

                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Type</label>
                    <VueMultiselect :allowEmpty="false" v-model="selectedType" class="multiselect-blue"
                                    :options="typeOptions"
                                    :close-on-select="true" placeholder="Select Type" label="name" track-by="name"
                                    :show-labels="false"/>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Pre Order Status</label>
                    <VueMultiselect v-model="selectedPreOrderStatus" class="multiselect-blue"
                                    :options="preOrderStatusOptions"
                                    :close-on-select="true" placeholder="Select Pre Order Status" label="name"
                                    track-by="name"
                                    :allowEmpty="false" :show-labels="false"/>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Customer Portal Status</label>
                    <VueMultiselect v-model="selectedCustomerPortalStatus" class="multiselect-blue"
                                    :options="customerPortalStatusOptions"
                                    :close-on-select="true" placeholder="Select Customer Portal Status" label="name"
                                    track-by="name"
                                    :allowEmpty="false" :show-labels="false"/>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Return Appointment Status</label>
                    <VueMultiselect :allowEmpty="false" v-model="selectedReturnAppointmentStatus"
                                    class="multiselect-blue"
                                    :options="returnAppointmentStatusOptions"
                                    :close-on-select="true" placeholder="Select Return Appointment Status" label="name"
                                    track-by="name"
                                    :show-labels="false"/>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Activation Status</label>
                    <VueMultiselect v-model="selectedStatus" class="multiselect-blue" :options="statusOptions"
                                    :close-on-select="true" placeholder="Select Activation Status" label="name"
                                    track-by="name"
                                    :allowEmpty="false" :show-labels="false"/>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label>Description</label>
                <QuillEditor ref="description" v-model:content="putProductData.description" contentType="html"
                             toolbar="full"
                             theme="snow"/>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="updateBasicInfo">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>
          <!-- data-dismiss="modal" -->
          <button data-dismiss="modal" style="visibility: hidden"></button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import VueMultiselect from 'vue-multiselect'
import {QuillEditor} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {mapActions} from "vuex";

export default {
  name: "BasicInfoModal",
  components: {
    VueMultiselect,
    QuillEditor
  },
  props: ['basicInfoObj'],
  emits: ['getProductOnUpdate'],
  data() {
    return {
      selectedSource: {},
      putProductData: {
        name: '',
        product_category_id: '',
        type: '',
        description: '',
        pre_order: '',
        return_appointment_status: '',
        customer_portal_status: '',
        status: '',
        source: '',
      },

      selectedCategory: {
        value: '',
        name: 'None'
      },

      selectedType: {
        value: '',
        name: 'None'
      },

      selectedStatus: {
        value: '',
        name: 'None'
      },

      selectedPreOrderStatus: {
        value: '',
        name: 'None'
      },

      selectedReturnAppointmentStatus: {
        value: '',
        name: 'None'
      },

      selectedCustomerPortalStatus: {
        value: '',
        name: 'None'
      },

      typeOptions: [
        {
          value: '',
          name: 'None'
        },
        {
          value: 0,
          name: 'Hardware'
        },
        {
          value: 1,
          name: 'Software'
        },
      ],

      preOrderStatusOptions: [
        {
          value: '',
          name: 'None'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ],

      returnAppointmentStatusOptions: [
        {
          value: '',
          name: 'None'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ],

      customerPortalStatusOptions: [
        {
          value: '',
          name: 'None'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ],

      statusOptions: [
        {
          value: '',
          name: 'None'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ],

      getProductCategoriesParams: {
        status: 1,
        order_by_name: 'ASC',
      },
    }
  },
  watch: {
    async basicInfoObj(value) {
      if (value.product_category_id) {
        let typeIndex = this.categoryOptions.findIndex(item => item.value === value.product_category_id);
        if (typeIndex !== -1) {
          let type = this.categoryOptions[typeIndex];
          this.selectedCategory = {
            value: type.value,
            name: type.name
          };
        }
      }

      if (value.type) {
        let typeIndex = this.typeOptions.findIndex(item => item.name === value.type);
        if (typeIndex !== -1) {
          let type = this.typeOptions[typeIndex];
          this.selectedType = {
            value: type.value,
            name: type.name
          };
        }
      }

      if (value.pre_order) {
        let preOrderStatusOptionsIndex = this.preOrderStatusOptions.findIndex(item => item.name === value.pre_order);
        if (preOrderStatusOptionsIndex !== -1) {
          let preOrderStatus = this.preOrderStatusOptions[preOrderStatusOptionsIndex];
          this.selectedPreOrderStatus = {
            value: preOrderStatus.value,
            name: preOrderStatus.name
          };
        }
      }

      if (value.return_appointment_status) {
        let ReturnAppointmentStatusOptionsIndex = this.returnAppointmentStatusOptions.findIndex(item => item.name === value.return_appointment_status);
        if (ReturnAppointmentStatusOptionsIndex !== -1) {
          let returnAppointmentStatus = this.returnAppointmentStatusOptions[ReturnAppointmentStatusOptionsIndex];
          this.selectedReturnAppointmentStatus = {
            value: returnAppointmentStatus.value,
            name: returnAppointmentStatus.name
          };
        }
      }

      if (value.customer_portal_status) {
        let customerPortalStatusOptionsIndex = this.customerPortalStatusOptions.findIndex(item => item.name === value.customer_portal_status);
        if (customerPortalStatusOptionsIndex !== -1) {
          let customerPortalStatus = this.customerPortalStatusOptions[customerPortalStatusOptionsIndex];
          this.selectedCustomerPortalStatus = {
            value: customerPortalStatus.value,
            name: customerPortalStatus.name
          };
        }
      }

      if (value.status) {
        let statusIndex = this.statusOptions.findIndex(item => item.name === value.status);
        if (statusIndex !== -1) {
          let status = this.statusOptions[statusIndex];
          this.selectedStatus = {
            value: status.value,
            name: status.name
          };
        }
      }
      if (value.source) {
        const selectedSourceIndex = this.sourceOptions.findIndex(function(source){
          return source.name.toString().trim().toLowerCase() === value.source.toString().trim().toLowerCase()});
        if (selectedSourceIndex !== -1) {
          const source = this.sourceOptions[selectedSourceIndex];
          this.selectedSource = {
            value: source.value,
            name: source.name
          };
        }
      }
      this.putProductData = {
        name: value.name,
        description: value.description,

      };

      this.$refs.description.setHTML(this.putProductData.description);
    },
    selectedCategory(selectedCategory) {
      this.putProductData.product_category_id = selectedCategory.value;
    },
    selectedType(selectedType) {
      this.putProductData.type = selectedType.value;
    },
    selectedStatus(selectedStatus) {
      this.putProductData.status = selectedStatus.value;
    },
    selectedPreOrderStatus(selectedPreOrderStatus) {
      this.putProductData.pre_order = selectedPreOrderStatus.value;
    },
    selectedReturnAppointmentStatus(selectedReturnAppointmentStatus) {
      this.putProductData.return_appointment_status = selectedReturnAppointmentStatus.value;
    },
    selectedCustomerPortalStatus(selectedCustomerPortalStatus) {
      this.putProductData.customer_portal_status = selectedCustomerPortalStatus.value;
    },
    selectedSource(selectedSource) {
      this.putProductData.source = selectedSource.value;
    },
  },
  computed: {
    categoryOptions() {
      let types = this.$store.getters['appProductCategories/productCategories'].map((type) => {
        let id = type.id;
        let name = type.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...types];
    },
    sourceOptions() {
      let sources = this.$store.getters['appReferrals/referrals'].map(item => {
        return {
          value: item.id,
          name: item.code,
        };
      })
      return [...sources];
    },
  },
  methods: {
    ...mapActions({
      getProductCategories: 'appProductCategories/getProductCategories',
      getReferral: "appReferrals/getReferrals",
    }),

    async getProductCategoryList() {
      await this.getProductCategories(this.getProductCategoriesParams).then((response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      })
    },
    updateBasicInfo() {
      this.$emit('getProductOnUpdate', {modalName: 'basicInfoEditModal', data: this.putProductData})
    }
  },
  async mounted() {
    await this.getReferral();
    await this.getProductCategoryList();
  }
}
</script>

<style scoped>

</style>